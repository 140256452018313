import React from 'react'
import './BasicContent.scss'

const BasicContent = ({ block }) => {
  const blockCopy = block.block_copy
  return(
    <div className="container post-content-container">
      <div className="row">
        <div className="grid-item post-content" dangerouslySetInnerHTML={{ __html: blockCopy }}></div>
      </div>
    </div>
  )
}

export default BasicContent
