import React from 'react'

const BasicHeader = ({ heroImage, defaultImage }) => {
  const headerStyle = {
    backgroundImage: `url(${heroImage})`
  }
  return (
    <header style={headerStyle}>
      <img className="mobile-hero" src={defaultImage} />
    </header>
  )
}

export default BasicHeader
