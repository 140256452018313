import React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Block from '../components/blocks/Block'
import BasicContent from '../components/blocks/BasicContent/BasicContent'
import ImageAndText from '../components/blocks/ImageAndText/ImageAndText'

import DefaultTemplate from '../components/PostTemplates/DefaultTemplate/DefaultTemplate'
import CustomTemplate from '../components/PostTemplates/CustomTemplate/CustomTemplate'
import PostMeta from '../components/PostMeta/PostMeta'

import BasicHeader from '../components/BasicHeader/BasicHeader'
import CustomHeader from '../components/CustomHeader/CustomHeader'

const PostTemplate = ({ data }) => {
  const post = data.wordpressPost

  const blockComponentTypes = {
    BasicContent,
    ImageAndText
  }

  if (!post) {
    return(<></>)
  }

  const { title, blocks = [], template, author, author_photo, date } = post

  let headerBlock, contentBlocks, headerComponent
  let customHeader = false

  if (blocks.length && blocks[0].layout === 'header-block') {
    headerBlock = blocks[0]
    headerComponent = <BasicHeader heroImage={headerBlock.block_image && headerBlock.block_image.source_url} defaultImage={post.featured_media && post.featured_media.source_url} />
    contentBlocks = blocks.slice(1)
  } else if (blocks.length && blocks[0].layout === 'custom-header-block') {
    headerBlock = blocks[0]
    headerComponent = <CustomHeader header={headerBlock} />
    customHeader = true
    contentBlocks = blocks.slice(1)
  } else {
    contentBlocks = blocks
  }

  let TemplateComponent
  if (template && template.indexOf('custom')) {
    TemplateComponent = CustomTemplate
  } else {
    TemplateComponent = DefaultTemplate
  }

  return (
    <Layout>
      <TemplateComponent post={post} headerComponent={headerComponent} hasCustomHeader={customHeader}>
        <PostMeta author={author} postDate={date} authorPhoto={author_photo}></PostMeta>
        {contentBlocks.map((block, index) => {
          const blockLayout = block.layout.split('-').map(x => x.slice(0,1).toUpperCase() + x.slice(1)).join('')
          if (!blockComponentTypes[blockLayout]) {
            console.error('No matching block layout found')
            return
          }
          const BlockComponent = blockComponentTypes[blockLayout]
          return (
            <Block blockClass={block.custom_classes} blockId={block.custom_id} blockLayout={block.layout} blockStyle={block.style} key={index}>
              <BlockComponent block={block} />
            </Block>
          )
        })}
      </TemplateComponent>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      date
      template
      blocks {
        accent_color
        block_copy
        block_image {
          source_url
        }
        block_style
        custom_classes
        custom_id
        layout
      }
      featured_media {
        source_url
      }
      author {
        name
        avatar_urls {
          wordpress_96
        }
      }
      author_photo {
        source_url
      }
    }
  }
`