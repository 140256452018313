import React from 'react'
import './CustomTemplate.scss'

const CustomTemplate = ({ post, headerComponent, hasCustomHeader, children }) => {
  const articleClass = (hasCustomHeader) ? 'custom-header-true' : 'custom-header-false'
  return (
    <article className={`post-type-custom ${articleClass}`}>
      {headerComponent}
      <div className="article-inner container">
        {!hasCustomHeader ? <h1 dangerouslySetInnerHTML={{ __html: post.title }}></h1> : ''}
          {children}
      </div>
    </article>
  )

}

export default CustomTemplate
