import React from 'react'

const Block = ({blockClass, blockId, blockLayout, blockStyle = 'default', children}) => {
  return(
    <div className={`block ${blockClass} layout-${blockLayout} style-${blockStyle}`} id={blockId}>
      {children}
    </div>
  )
}

export default Block