import React from 'react'

const PostMeta = ({ author, authorPhoto, postDate}) => {
  const thisDate = new Date( postDate );
  const authorImg = ( authorPhoto && authorPhoto.source_url ) ? <img src={`${authorPhoto.source_url}`} alt={`${author.name}`} /> : '';

  return (
    <div className="post-meta-info grid-item">
      <p><span className="author-photo">{authorImg}</span>Words and Photos by <em>{author.name}</em> <em>{thisDate.toLocaleDateString()}</em></p>
    </div> 
  )
}

export default PostMeta
