import React from 'react'
import './ImageAndText.scss'

const ImageAndText = ({ block }) => {
  const blockCopy = block.block_copy
  const blockImage = block.block_image
  const blockStyle = block.block_style
  return(
    <>
      <div className="image-background" style={{backgroundImage: `url(${blockImage.source_url}`}}></div>
      <div className={`container ${blockStyle}`}>
        <div className="row">
          <div className="grid-item" dangerouslySetInnerHTML={{ __html: blockCopy }}></div>
        </div>
      </div>
    </>
  )
}

export default ImageAndText
