import React from 'react'

import BasicHeader from '../../BasicHeader/BasicHeader'

const DefaultTemplate = ({ post, headerComponent, hasCustomHeader, children }) => {
  const articleClass = (hasCustomHeader) ? 'custom-header-true' : 'custom-header-false'
  return (
    <article className={`post-type-default ${articleClass}`}>
      {headerComponent}
      <div className="article-inner container">
        {!hasCustomHeader &&
          <div className="row">
            <div className="grid-item">
              <h1 dangerouslySetInnerHTML={{ __html: post.title }}></h1>
            </div>
          </div>
        }
        <div className="row">
          {children}
        </div>
      </div>
    </article>
  )
}

export default DefaultTemplate
